import { createStore } from 'vuex'

export default createStore({
  state: {
    userId: '',
    userToken: null
  },
  getters: {
    getUserId: state => state.userId,
    getUserToken: state => state.userToken
  },
  mutations: {
    SET_USER_ID(state, userId) {
      state.userId = userId;
    },
    
    SET_USER_TOKEN(state, token) {
      state.userToken = token;
    }
  },
  actions: {
    updateUserId({ commit }, userId) {
      commit('SET_USER_ID', userId);
    },
    
    updateUserToken({ commit }, token) {
      commit('SET_USER_TOKEN', token);
    },
  },
})

