<template>
  <div class="container">
    <div class="row g-3 mt-2 align-items-center">
      <p v-if="errorMessage" v-show="showErrorMessage" class="mt-4 alert alert-danger">
        {{ errorMessage }}
      </p>
      <p v-if="successMessage" v-show="showSuccessMessage" class="mt-4 alert alert-success">
        {{ successMessage }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FlashedMessages',
  props: ['successMessage', 'errorMessage', 'successMessageTimeout', 'errorMessageTimeout'],
  data() {
    return {
      showSuccessMessage: true,
      showErrorMessage: true,
    }
  },
  mounted() {
    let smTO = parseInt(this.successMessageTimeout)
    let emTO = parseInt(this.errorMessageTimeout)

    if (!isNaN(smTO)) {
      setTimeout(() => {
        this.showSuccessMessage = false
      }, smTO)
    }
    if (!isNaN(emTO)) {
      setTimeout(() => {
        this.showErrorMessage = false
      }, emTO)
    }
  },
}

</script>

<style>

</style>