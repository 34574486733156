<template>
  <div>
  </div>
</template>

<script>
export default {
  name: 'LogoutView',
  mounted() {
    localStorage.removeItem('userId')
    localStorage.removeItem('userToken')

    this.$store.dispatch('updateUserId', null)
    this.$store.dispatch('updateUserToken', null)
    
    this.$router.push('/')
  },
}
</script>

<style>

</style>