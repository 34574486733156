<template>
  <div class="container-fluid text-center mt-4">
    <h1>About Tesla App</h1>
    <h2>(c) Sumit Rana</h2>
    <p v-if="getUserId">Logged in as {{ getUserId }}</p>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'AboutView',

  computed: {
    ...mapGetters([
      'getUserId'   // Maps this.getUserId to this.$store.getters.getUserId
    ]),
  },
}
</script>