<template>
  <div class="container mt-4">
    <FlashedMessages :errorMessage="errorMessage"/>
    <div class="row mb-3">
      <label for="userId" class="col-sm-2 col-form-label form-control-xlg">User ID</label>
      <div class="col-sm-10">
        <input class="form-control form-control-xlg" id="userId" v-model="userId" placeholder="Enter User ID">
      </div>
    </div>
    <div class="row mb-3">
      <label for="password" class="col-sm-2 col-form-label form-control-xlg">Password</label>
      <div class="col-sm-10">
        <input type="password" class="form-control form-control-xlg" id="password" v-model="password" placeholder="Enter Password">
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-sm-10 offset-sm-2">
        <div class="form-check">
          <input type="checkbox" class="form-check-input form-control-xlg" id="rememberMe" v-model="rememberMe"/>
          <label class="form-check-label form-control-xlg" for="rememberMe">Remember me</label>
        </div>
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-sm-12 text-center">
        <button v-if="attemptingLogin" class="btn btn-primary btn-xlg" type="button" disabled>
          <span class="spinner-border align-bottom m-3" style="width: 3rem; height: 3rem;" role="status" aria-hidden="true"></span>
          <span role="status">Please wait ...</span>
        </button>
        <button v-else @click.prevent="onSubmit" type="button" class="btn btn-primary btn-xlg">
          <span role="status">Sign In</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { API_ENDPOINTS } from '../constants.js'
import FlashedMessages from '@/components/FlashedMessages.vue'

export default {
  name: 'LoginView',
  components: {
    FlashedMessages
  },
  data() {
    return {
      userId: '',
      password: '',
      attemptingLogin: false,
      rememberMe: false,
      errorMessage: null,
    };
  },
  methods: {
    getRedirectURL() {
      const redirect = this.$route.query.redirect
      if (redirect && redirect.startsWith('/') && !redirect.startsWith('//')) {
        return redirect
      }
      return '/'
    },
    onSubmit() {
      if (this.userId === '') {
        this.errorMessage = 'Please enter your user id.'
        return
      }

      if (this.password === '') {
        this.errorMessage = 'Please enter your password.'
        return
      }

      const userData = {
        userid: this.userId,
        password: this.password
      }

      this.attemptingLogin = true
      axios.post(API_ENDPOINTS.POST_AUTH, userData)
        .then(response => {
          const userId = userData.userid
          const userToken = response.data.token

          this.errorMessage = null
          this.$store.dispatch('updateUserId', userId)
          this.$store.dispatch('updateUserToken', userToken)

          if (this.rememberMe) {
            localStorage.setItem('userId', userId)
            localStorage.setItem('userToken', userToken)
          } else {
            localStorage.removeItem('userId')
            localStorage.removeItem('userToken')
          }

          this.$router.push(this.getRedirectURL())
        })
        .catch(error => {
          if (error?.response?.status === 401) {
            this.errorMessage = 'Login failed. Please check your credentials and try again.'
          } else {
            console.error(error)
            this.errorMessage = `Login failed. Please try again. See console for details. Error: ${error}.`
          }
        })
        .finally(() => {
          this.attemptingLogin = false
        })
    }
  },
  mounted() {
    const userId = localStorage.getItem('userId')
    const userToken = localStorage.getItem('userToken')
    
    if (userId && userToken) {
      // TODO Should check if the token is still valid

      this.$store.dispatch('updateUserId', userId)
      this.$store.dispatch('updateUserToken', userToken)

      this.$router.push(this.getRedirectURL())
    }
  },
};
</script>
