<template>
  <div class="container mt-4">
    <FlashedMessages :successMessage="successMessage" :success-message-timeout="successMessageTimeout" :errorMessage="errorMessage" :error-message-timeout="errorMessageTimeout"/>
    <div class="row mb-3">
      <div class="col-sm-12">
        <div class="btn-group d-flex" role="group" aria-label="Send Message To">
          <template v-for="recipient in recipients" :key="recipient.id">
            <input v-model="sendTo" name="recipient" type="radio" autocomplete="off" class="btn-check" :value="recipient.id" :id="recipient.id"/>
            <label class="btn btn-outline-primary btn-xlg" :for="recipient.id">{{ recipient.name }}</label>
          </template>
        </div>
      </div>
    </div>

    <div class="row mb-3">
      <div class="col-sm-12">
        <div class="btn-group d-flex" role="group" aria-label="Message text">
          <template v-for="text in quickText" :key="text">
            <input v-model="message" name="message" type="radio" autocomplete="off" class="btn-check" :value="text" :id="text"/>
            <label class="btn btn-outline-primary btn-xlg" :for="text">{{ text }}</label>
          </template>
        </div>
      </div>
    </div>

    <div class="row mb-3">
      <div class="col-sm-10">
        <input v-model="customText" type="text" class="form-control form-control-xlg" id="customText" placeholder="Enter your own message" aria-label="Custom text"/>
      </div>
      <div class="col-sm-2 text-end">
        <button @click.prevent="sendMessage" type="button" class="btn btn-primary btn-xlg">Send</button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { API_ENDPOINTS } from '../constants.js'
import { mapGetters } from 'vuex'
import FlashedMessages from '@/components/FlashedMessages.vue'

export default {
  name: 'MessageView',
  components: {
    FlashedMessages
  },
  data() {
    return {
      // TODO Ideally this would be loaded via an API
      recipients: [
        {id: 'divya', name: 'Divya'},
        {id: 'rhea', name: 'Rhea'},
        {id: 'anya', name: 'Anya'},
        {id: 'sumit', name: 'Sumit'},
      ],
      quickText: [
        'On my way',
        'Call me please',
        'I love you',
        'I miss you'
      ],
      sendTo: null,
      message: null,
      customText: null,
      successMessage: null,
      errorMessage: null,
      errorMessageTimeout: 15000,
      successMessageTimeout: 15000,
    }
  },
  computed: {
    messageText() {
      return (this.customText === null) ? this.message : this.customText
    },
    ...mapGetters([
      'getUserToken'   // Maps this.getUserToken to this.$store.getters.getUserToken
    ]),
  },
  methods: {
    sendMessage() {
      if (this.sendTo === null) {
        this.errorMessage = 'Please select a recipient.'
        return
      }

      if (this.messageText === null) {
        this.errorMessage = 'Please select a message.'
        return
      }
      
      const messageData = {
        to: this.sendTo,
        message: this.messageText
      }

      const token = this.getUserToken
      const config = {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }

      axios.post(API_ENDPOINTS.POST_MESSAGES, messageData, config)
        .then(response => {
          this.successMessage = `Will send '${messageData.message}' to '${messageData.to}'.`
          this.errorMessage = null
        })
        .catch(error => {
          console.error(error)
          this.errorMessage = `Could not send message. Please try again. Error: ${error}.`
          this.successMessage = null
        })
    }
  }
}

</script>
