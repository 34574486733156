<template>
  <div class="weather">
    <div ref="htmlContainerVerona"></div>
    <div ref="htmlContainerLaValle"></div>
  </div>
</template>

<script>
export default {
  name: 'WeatherView',
  mounted() {
    this.injectHTML()
  },
  methods: {
    injectHTML() {
      // Verona
      const containerVerona = this.$refs.htmlContainerVerona;

      const htmlContentVerona = '<a class="weatherwidget-io" href="https://forecast7.com/en/43d00n89d57/53593/?unit=us" data-label_1="Verona, WI" data-label_2="Weather" data-icons="Climacons Animated" data-days="3" data-theme="pure">Verona, WI Weather</a>';
      containerVerona.innerHTML = htmlContentVerona;

      const scriptVerona = document.createElement('script');
      scriptVerona.type = 'text/javascript';
      scriptVerona.text = "!function(d,s,id){var js,fjs=d.getElementsByTagName(s)[0];if(!d.getElementById(id)){js=d.createElement(s);js.id=id;js.src='https://weatherwidget.io/js/widget.min.js';fjs.parentNode.insertBefore(js,fjs);}}(document,'script','weatherwidget-io-js');"
      containerVerona.appendChild(scriptVerona);

      // La Valle
      const containerLaValle = this.$refs.htmlContainerLaValle;

      const htmlContentLaValle = '<a class="weatherwidget-io" href="https://forecast7.com/en/43d58n90d13/53941/?unit=us" data-label_1="La Valle, WI" data-label_2="Weather" data-icons="Climacons Animated" data-days="3" data-theme="pure" >La Valle, WI Weather</a>'
      containerLaValle.innerHTML = htmlContentLaValle;

      const scriptLaValle = document.createElement('script');
      scriptLaValle.type = 'text/javascript';
      scriptLaValle.text = "!function(d,s,id){var js,fjs=d.getElementsByTagName(s)[0];if(!d.getElementById(id)){js=d.createElement(s);js.id=id;js.src='https://weatherwidget.io/js/widget.min.js';fjs.parentNode.insertBefore(js,fjs);}}(document,'script','weatherwidget-io-js');"
      containerLaValle.appendChild(scriptLaValle);
    }
  }
}
</script>

<style>

.weather {
  padding: var(--app-padding-view);
  align-content: center;
}

</style>