const API_BASE_URL_DEFAULT = ''     // const API_BASE_URL_DEFAULT = 'http://localhost:3000'

const API_BASE_URL = process.env.API_BASE_URL || API_BASE_URL_DEFAULT

export const API_ENDPOINTS = {
  POST_AUTH: `${API_BASE_URL}/api/auth`,
  POST_MESSAGES: `${API_BASE_URL}/api/messages`,
}

export const APP_CONSTANTS = {
  APP_NAME: 'Tesla App'
}