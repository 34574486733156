<template>
  <nav class="navbar navbar-expand-lg sticky-top bg-primary" data-bs-theme="dark">
    <div class="container-fluid">
      <a class="navbar-brand x-navbar-xl mr-4" href="#">
        <img src="@/assets/logo.png" alt="logo" width="48" height="48" class="d-inline-block align-top">
      </a>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
          <li class="nav-item"><router-link class="nav-link" to="/">Home</router-link></li>
          <li class="nav-item"><router-link class="nav-link" to="/message">Message</router-link></li>
          <li class="nav-item"><router-link class="nav-link" to="/search">Search</router-link></li>
          <li class="nav-item"><router-link class="nav-link" to="/calc">Calc</router-link></li>
          <!-- <li class="nav-item"><router-link class="nav-link" to="/about">About</router-link></li> -->
        </ul>
        <div class="d-flex navbar-nav">
          <router-link v-if="getUserId" class="nav-link" to="/logout">Logout</router-link>
          <router-link v-else class="nav-link" to="/login">Login</router-link>
        </div>
      </div>
    </div>
  </nav>
  <router-view/>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters([
      'getUserId',
    ]),
  },
  methods: {
    getRedirectURL() {
      const redirect = this.$route.query.redirect
      if (redirect && redirect.startsWith('/') && !redirect.startsWith('//')) {
        return redirect
      }
      return '/'
    },
  },
  mounted() {
    // TODO This code should be refactored so it is not duplicated in LoginView.vue
    const userId = localStorage.getItem('userId')
    const userToken = localStorage.getItem('userToken')
    
    if (userId && userToken) {
      // TODO Should check if the token is still valid

      this.$store.dispatch('updateUserId', userId)
      this.$store.dispatch('updateUserToken', userToken)

      this.$router.push(this.getRedirectURL())
    }
  },
}
</script>

<style>

:root {
  --app-padding-view: 30px;
  --app-font-size: xxx-large;
  --app-font-weight-semi-bold: 600;
}

#app {
  font-size: var(--app-font-size);
}

nav a.active {
  font-weight: var(--app-font-weight-semi-bold);
}

.form-control-xlg {
  font-size: var(--app-font-size);
}

.btn-xlg {
  font-size: var(--app-font-size);
}

</style>