import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '@/views/HomeView.vue'
import MessageView from '@/views/MessageView.vue'
import SearchView from '@/views/SearchView.vue'
import CalcView from '@/views/CalcView.vue'
import AboutView from '@/views/AboutView.vue'
import LoginView from '@/views/LoginView.vue'
import LogoutView from '@/views/LogoutView.vue'
import store from '@/store'
import { APP_CONSTANTS } from '@/constants'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/message',
    name: 'message',
    component: MessageView,
    meta: { title: 'Send messages', requiresAuth: true },
  },
  {
    path: '/search',
    name: 'search',
    component: SearchView,
    meta: { title: 'Search' },
  },
  {
    path: '/calc',
    name: 'calc',
    component: CalcView,
    meta: { title: 'Calculator' },
  },
  {
    path: '/about',
    name: 'about',
    component: AboutView,
    meta: { title: 'About' },
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView
  },
  {
    path: '/logout',
    name: 'logout',
    component: LogoutView
  },
  {
    path: '/index',   // Needed for Tesla
    redirect: '/'
  },
  // {
  //   path: '/:pathMatch(.*)*',
  //   name: 'not-found',
  //   component: () => import('@/views/NotFoundView.vue')
  // }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkExactActiveClass: 'active',
})

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth)

  if (requiresAuth) {
    // const isAuthenticated = true
    const isAuthenticated = (store.state.userToken !== null)
  
    if (!isAuthenticated) {
      next({
        name: 'login',
        query: { redirect: to.fullPath }
      })
    } else {
      next()
    }
  } else {
    next()
  }
})

router.afterEach((to, from) => {
  let title = APP_CONSTANTS.APP_NAME

  if (to.meta.title) { title = `${to.meta.title} - ${title}` }

  document.title = title
})

export default router

