<template>
  <div class="navicon">
    <a v-for="link in links" :key="link.url" :href="link.url">
      <img :src="link.icon"/>
    </a>
  </div>
</template>

<script>
export default {
  data() {
    return {
      links: [
        { url: 'http://newsnationnow.com', icon: '/static/nn.jpg'},
        { url: 'http://cnbc.com', icon: '/static/cnbc.png'},
        { url: 'http://wsj.com', icon: '/static/wsj.png'},
        { url: 'http://espncricinfo.com', icon: '/static/eci.png'},
        { url: 'http://waze.com', icon: '/static/waze.png'},
      ]
    }
  }
}
</script>

<style>

.navicon {
  display: flex;
  align-items: center; /* Align images vertically */
  justify-content: space-around; /* Distribute space between images */
  overflow: hidden; /* Prevents overflow of the container */
}

.navicon a {
  flex: 1; /* Allows each span to grow */
  max-width: calc(100% - 20px); /* Adjusts max-width to account for padding */
  margin: 10px; /* Adds spacing between the images */
  text-decoration: none; /* Optional: Removes underline from links */
  box-sizing: border-box; /* Ensures padding is included in the element's total width and height */
}

.navicon img {
  width: 100%; /* Makes images fill their link container */
  height: auto; /* Keeps the aspect ratio of the images */
  object-fit: cover; /* Optional: Crop images to cover their flex container */
  display: block; /* Removes any default inline spacing */
  border-radius: 50%;
}

</style>