<template>
  <div class="container text-center">
    <div class="row mt-2">
      <div class="col-3" v-show="!showLog">
        
      </div>
      <div class="col">
        <div class="form-group d-flex">
          <input type="text" class="form-control form-control-xlg text-end" :placeholder="shownValue" @click="toggleLog" readonly>
        </div>
        <div class="btn-group d-flex mt-2" role="group" aria-label="Basic example">
          <button type="button" class="btn btn-secondary btn-xlg" @click="clear">C</button>
          <button type="button" class="btn btn-secondary btn-xlg" @click="negate">±</button>
          <button type="button" class="btn btn-secondary btn-xlg" @click="percent">%</button>
          <button type="button" class="btn btn-secondary btn-xlg" @click="operation('÷')">÷</button>
        </div>
        <div class="btn-group d-flex mt-1" role="group" aria-label="Basic example">
          <button type="button" class="btn btn-secondary btn-xlg" @click="append('7')">7</button>
          <button type="button" class="btn btn-secondary btn-xlg" @click="append('8')">8</button>
          <button type="button" class="btn btn-secondary btn-xlg" @click="append('9')">9</button>
          <button type="button" class="btn btn-secondary btn-xlg" @click="operation('×')">×</button>
        </div>
        <div class="btn-group d-flex mt-1" role="group" aria-label="Basic example">
          <button type="button" class="btn btn-secondary btn-xlg" @click="append('4')">4</button>
          <button type="button" class="btn btn-secondary btn-xlg" @click="append('5')">5</button>
          <button type="button" class="btn btn-secondary btn-xlg" @click="append('6')">6</button>
          <button type="button" class="btn btn-secondary btn-xlg" @click="operation('-')">–</button>
        </div>
        <div class="btn-group d-flex mt-1" role="group" aria-label="Basic example">
          <button type="button" class="btn btn-secondary btn-xlg" @click="append('1')">1</button>
          <button type="button" class="btn btn-secondary btn-xlg" @click="append('2')">2</button>
          <button type="button" class="btn btn-secondary btn-xlg" @click="append('3')">3</button>
          <button type="button" class="btn btn-secondary btn-xlg" @click="operation('+')">+</button>
        </div>
        <div class="btn-group d-flex mt-1" role="group" aria-label="Basic example">
          <button type="button" class="btn btn-secondary btn-xlg" @click="append('0')">0</button>
          <button type="button" class="btn btn-secondary btn-xlg" @click="decimal">.</button>
          <button type="button" class="btn btn-secondary btn-xlg" @click="backspace">«</button>
          <button type="button" class="btn btn-secondary btn-xlg" @click="equals">=</button>
        </div>
      </div>
      <div class="col-3" v-show="!showLog">
        
      </div>
      <div class="col-6" v-show="showLog">
        <div class="row text-end mt-2" v-show="showLog">
          <div v-for="(entry, index) in calcLog" :key="index" class="text-muted form-control-lg">{{ entry }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'CalcView',
  data() {
    return {
      lastValue: '',
      nextValue: '',
      operator: null,
      suspendUpdate: false,
      calcLog: [],
      showLog: false,
    }
  },
  computed: {
    shownValue() {
      let val

      if (this.suspendUpdate) { val = this.lastValue }
      else { val = this.nextValue }

      if (val !== '') { return this.formatWithThousands(val) }   // val.replace(/\B(?=(\d{3})+(?!\d))/g, ',') 
      else { return '0' }
    }
  },
  methods: {
    append(value) {
      if (value === '0') {
        if (this.nextValue === '' || this.nextValue === '-') { return }
      }
      this.suspendUpdate = false
      this.nextValue += value
    },
    clear() {
      this.lastValue = ''
      this.nextValue = ''
      this.operator = null
      this.suspendUpdate = false
      this.calcLog = []
    },
    backspace() {
      this.nextValue = this.nextValue.slice(0, -1)
    },
    negate() {
      if (this.nextValue.startsWith('-')) { this.nextValue = this.nextValue.slice(1) }
      else { this.nextValue = '-' + this.nextValue}
    },
    percent() {
      this.nextValue = (this.strToValue(this.nextValue) / 100).toString()
      if (this.nextValue === '0') { this.nextValue = '' }
    },
    decimal() {
      if (!this.nextValue.includes('.')) { this.nextValue += '.' }
    },
    equals() {
      if (this.lastValue === '' || this.nextValue === '' || this.operator === null) { return }
      
      const floatLastValue = this.strToValue(this.lastValue)
      const floatNextValue = this.strToValue(this.nextValue)

      let newValue
      switch (this.operator) {
        case '+':
          newValue = floatLastValue + floatNextValue
          break
        case '-':
          newValue = floatLastValue - floatNextValue
          break
        case '×':
          newValue = floatLastValue * floatNextValue
          break
        case '÷':
          if (floatNextValue === 0) { return }
          newValue = floatLastValue / floatNextValue
          break
      }

      newValue = newValue.toFixed(4)
      this.calcLog.push(`${this.operator} ${this.formatWithThousands(floatNextValue)} = ${this.formatWithThousands(newValue)}`)

      this.lastValue = ''
      this.nextValue = newValue.toString()
      if (this.nextValue === '0') { this.nextValue = '' }
    },
    operation(operator) {
      if (this.operator !== null) { this.equals() }
      else { this.calcLog.push(this.formatWithThousands(this.nextValue)) }

      this.lastValue = this.nextValue
      this.nextValue = ''
      this.operator = operator
      this.suspendUpdate = true
    },
    strToValue(value) {
      if (value === '') {
        return 0
      }
      else {
        return parseFloat(value)
      }
    },
    formatWithThousands(value) {
      const floatVal = parseFloat(value)
      return floatVal.toLocaleString()
    },
    toggleLog() {
      this.showLog = !this.showLog
    }
  }
}
</script>
