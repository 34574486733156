<template>
  <div class="container-md mt-4">
    <div class="row">
      <form class="d-flex" role="search" method="get" action="https://duckduckgo.com/">
        <input type="search" class="form-control form-control-xlg me-3" placeholder="Search Duckduckgo" aria-label="Search" name="q"/>
        <button type="submit" class="btn btn-primary btn-xlg">Search</button>
      </form>
    </div>
    <div class="row mt-4">
      <form @submit.prevent="searchFlight" class="d-flex" role="search">
        <input type="search" class="form-control form-control-xlg me-3" v-model="flightNumber" placeholder="Search FlightAware" aria-label="Search Flight"/>
        <button type="submit" class="btn btn-primary btn-xlg">Search</button>
      </form>
    </div>
  </div>
</template>

<script>

export default {
  name: 'SearchView',
  data() {
    return {
      flightNumber: ''
    }
  },
  methods: {
    searchFlight() {
      const flightNumber = this.flightNumber.replace(/\s/g, '').toUpperCase()

      if (!flightNumber) { return }
      window.location.href = `https://flightaware.com/live/flight/${flightNumber}`
    }
  }
}

</script>