<template>
  <div class="container-fluid">
    <NavIcons/>
    <WeatherView/>
  </div>
</template>

<script>
import NavIcons from '@/components/NavIcons.vue'
import WeatherView from '@/components/WeatherView.vue'

export default {
  name: 'HomeView',
  components: {
    NavIcons,
    WeatherView
  }
}
</script>
